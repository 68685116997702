import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import AlertIcon from '../assets/svg/ss/bell.svg';
import lstrings from '../lstrings.js';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import MeasureIcon from '../assets/svg/ss/measure-tape.svg';
import axios from 'axios';
import config from "../config.json";
import Chip from '@material-ui/core/Chip';
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import PushNotifications from './pushnotifications';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    image: {
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(2.5),
        color: theme.palette.grey[500],
    },
    textarea: {
        resize: "both"
    },
    title: {
        marginLeft: 30
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {/* <img src={MeasureIcon} className={classes.image} width='25' alt="" /> */}
            <Typography className={classes.title} variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function PushNotificationSelected(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: 'calc(100%)',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
        },
        papernew: {
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[2],
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            outline: 'none',
            // padding: '10px 20px',
            width: '100%',
            borderRadius: '5px',
            overflow: 'auto',
            depth: 1,
            // marginTop: '10px',
            // marginBottom: '10px',
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            marginBottom: '10px',
        },
        inputFields: {
            marginTop: 10,
        },
        submit: {
            display: 'flex',
            justifyContent: 'flex-end',
            // marginTop: '15px',
            // margin: '5px',
        },
        formControl: {
            marginTop: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [message, set_message] = React.useState("");
    const [message_error, set_message_error] = React.useState(null);

    const [contactingServer, setContactingServer] = React.useState(false);

    const [filtersList, setFiltersList] = React.useState(["iOS", "Android"]);
    const [sortFiltersList, setSortFiltersList] = React.useState(["Created Date Desc", "UserName Asc", "UserName Desc"]);
    // const [sortFilterSelected, setSortFilterSelected] = React.useState("Created Date Desc");
    // const [searchByFilterSelected, setSearchByFilterSelected] = React.useState("Username");
    const [searchByFiltersList, setSearchByFiltersList] = React.useState(["Username", "Email", "Country"]);

    useEffect(() => {
    }, []);

    const send = () => {
        console.log(message);
        props.send(message);
    }

    return (
        <div>
            <Dialog fullWidth={true} onClose={props.close} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="alert-dialog-title">{"Notification to Selected"}</DialogTitle>
                <DialogContent>
                    <form className={classes.papernew} autoComplete="off" noValidate>
                        <TextField className={classes.inputFields} id="formControl_message" value={message}
                            label="message *" variant="outlined" multiline
                            onChange={(event) => { set_message(event.target.value); set_message_error(null); }} />
                        {message_error && <Alert className={classes.alert} severity="error"> {message_error} </Alert>}
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" onClick={props.close} disabled={contactingServer}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => send()} disabled={contactingServer}>Send</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
