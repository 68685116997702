import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Car from "../assets/Images/OnlyMARA.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Clap from "../assets/Images/clap.svg";
import vector from '../assets/Images/Vector.png';
import IconButton from '@material-ui/core/IconButton';

const CLIENT = {
    sandbox:
        "AaQdSvWFmBPDg8KqHHKH7gqpIQ1Y3uLz6L--4P6sxCcMvzY4EbF5vjrM2VgnPrArmezGeHl512qvcwUf",
    production:
        "AbTRhqCowKHGzj6tfM7Ijq95Kh6FbaVa9tgx2313saUtEHpHgPquIsGMvRuwwEnsmL7-wGW-HU5Zu-Rq"
};

const CLIENT_ID = CLIENT.production;
// process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showButtons: false,
            loading: true,
            paid: false
        };

        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
            !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver("react", {
                    React,
                    ReactDOM
                });
                this.setState({ loading: false, showButtons: true });
            }
        }
    }
    createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: +("Tip Jar: $" + this.props.amount),
                    amount: {
                        currency_code: "USD",
                        value: this.props.amount
                    }
                }
            ]
        });
    };

    onApprove = (data, actions) => {
        actions.order.capture().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            console.log("Payment Approved: ", paymentData);
            this.setState({ showButtons: false, paid: true });
        });
    };

    openAppStore = () => {
        window.open("https://apps.apple.com/us/app/royelles/id1524674005", "_blank");
    };

    render() {
        const { showButtons, loading, paid } = this.state;

        return (
            <div className="main">
                {loading && <CircularProgress color="inherit" />}

                {showButtons && (
                    <div>
                        <div>
                            <p> Tip Amount ${this.props.amount}</p>
                            {/* <h2>Total checkout Amount ${this.props.amount}</h2> */}
                        </div>

                        <PayPalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                        />
                    </div>
                )}

                {!paid && (
                    <div className="" style={{ color: '#FFE341' }}>
                        <img alt="Mara" src={Car} style={{ width: '100%' }} />
                        <h3>
                            Thank you for championing the girls in your life with ${this.props.amount} {" "}
                            <img alt="clap" src={Clap} style={{ width: '25px' }} />
                        </h3>
                        <h3>Invite someone in your community circle to join the #RoyellesRevolution</h3>

                        <IconButton onClick={() => this.openAppStore()}>
                            <img src={vector} style={{ width: '120px' }} />
                            <span style={{ width: '100%', fontSize: '14px', fontWeight: 'bold', color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>BACK TO APP</span>
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}


export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)(PaypalButton);
