import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import LogoIcon from '../assets/Images/royellesLogo.png';
import lstrings from '../lstrings.js';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import config from "../config.json";
import checkImage from '../assets/svg/ss/check.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    papernew: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        outline: 'none',
        padding: '10px 20px',
        width: '100%',
        borderRadius: '10px',
        overflow: 'auto',
        depth: 3,
        margin: '5px',
    },
    grid: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '10px',
    },
    inputFields: {
        marginTop: 10,
    },
    submit: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '15px',
        margin: '5px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    // form: {
    //     width: '100%', // Fix IE 11 issue.
    //     marginTop: theme.spacing(1),
    // },
    // submit: {
    //     margin: theme.spacing(3, 0, 2),
    // },
    alert: {
        marginTop: -8
    },
    header: {
        position: 'absolute',
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px'
    },
    grid: {
        textAlign: 'center',
        margin: '8px 0px',
        fontWeight: 'bold'
    },
    text: {
        color: '#677788',
        textAlign: 'center',
        margin: '2px 0px !important',
        padding: '0px',
        fontWeight: '500'
    }
}));

export default function ResetPasswordFromForgot(props) {
    const [contactingServer, setContactingServer] = React.useState(false);
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [verifyingMsg, setVerifyingMsg] = React.useState(null);
    const [verified, setVerified] = React.useState(false);
    const [showBusy, setShowBusy] = React.useState(false);

    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [password, set_password] = React.useState('');
    const [password_error, set_password_error] = React.useState(null);

    const [confirm_password, set_confirm_password] = React.useState('');
    const [confirm_password_error, set_confirm_password_error] = React.useState(null);


    const theme = useTheme();
    const [language, setLanguage] = React.useState('en');
    const themeChanged = (event) => {
        setLanguage(event.target.value);
        let direction = 'ltr';
        let lang = event.target.value;
        if (event.target.value === 'ar') {
            direction = 'rtl';
            theme.direction = 'rtl';
            lang = 'ar';
        }
        else if (event.target.value === 'en') {
            direction = 'ltr';
            theme.direction = 'ltr';
            lang = 'en';
        }
        else {
            direction = 'ltr';
            theme.direction = 'ltr';
            lang = 'en';
        }

        document.getElementsByTagName('html')[0].setAttribute("dir", direction);
        lstrings.setLanguage(lang);
    };

    const handleClose = () => {
        setAlertOpen(false);

    };

    const handleSave = async () => {
        if (password.length === 0) {
            setErrorMessage("Password cannot be empty");
            setShowError(true);
            return;
        }
        if (password !== confirm_password) {
            setErrorMessage("Password and Confirm password is not same");
            setShowError(true);
            return;
        }

        setShowError(false);
        setShowBusy(true);

        try {
            let postObj = {};
            postObj["token"] = props.token;
            postObj["username"] = props.username;
            postObj["password"] = password;

            let url = config["baseurl"] + "/forgot-reset-password";
            const response = await axios.post(url, postObj);
            setVerifyingMsg("Password Reset Successfull!!");
            setShowBusy(false);
            setVerified(true);
        }
        catch (e) {
            console.log("e:", e.message);
            // setVerifyingMsg(e.message);
            setErrorMessage("error in verifying, please try again or resend verification email from app");
            setShowError(true);
            setAlertOpen(true);
            setShowBusy(false);
        }
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Paper className={classes.grid}>
                        <img src={LogoIcon} width='300px' alt="" />
                        {!verifyingMsg &&
                            <form className={classes.papernew} autoComplete="off" noValidate>
                                <TextField className={classes.inputFields} id="formControl_password" defaultValue={password}
                                    label="Password *" variant="outlined"
                                    type="password"
                                    onChange={(event) => { set_password(event.target.value); set_password_error(null); }} />
                                {password_error && <Alert className={classes.alert} severity="error"> {password_error} </Alert>}

                                <TextField className={classes.inputFields} id="formControl_confirm_password" defaultValue={confirm_password}
                                    label="Confirm Password *" variant="outlined"
                                    type="password"
                                    onChange={(event) => { set_confirm_password(event.target.value); set_confirm_password_error(null); }} />
                                {confirm_password_error && <Alert className={classes.alert} severity="error"> {confirm_password_error} </Alert>}

                                <div className={classes.submit}>
                                    <Button style={{ marginLeft: 10 }} variant="contained" color="primary" onClick={handleSave} disabled={contactingServer}>{"SEND"}</Button>
                                </div>
                                {showBusy && <CircularProgress color="inherit" />}
                            </form>
                        }
                        {verifyingMsg &&
                            <Grid container>
                                <Grid item xs={12} className={classes.grid}>
                                    <img src={checkImage} width='100px' alt="" />
                                    <h3>{verifyingMsg}</h3>
                                    <Link to="/signin" > Back to Sign in </Link>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </div>
            </Grid>
            <Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Grid >
    );
}