import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import exhibitorsLogo from '../assets/svg/ss/exhibition.svg';
import notFoundImage from '../assets/svg/ss/page-not-found.svg';
import profileLogo from '../assets/svg/ss/profile.svg';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedTableToolbar from './enhancedToolbar';
import axios from 'axios';
import config from "../config.json";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Image, { Shimmer } from 'react-shimmer'
import { useHistory } from 'react-router-dom';
import lstrings from '../lstrings';
import DateFnsUtils from '@date-io/date-fns';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function createData(slno, data, createddate) {
  return { slno, data, createddate };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const dir = document.getElementsByTagName('html')[0].getAttribute('dir');
  const setDir = (dir === 'rtl' ? true : false);

  const headCells = [
    { id: 'slno', numeric: true, disablePadding: true, label: 'Username' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Parent Email' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email Verified' },
    { id: 'birthyear', numeric: false, disablePadding: false, label: 'Birth Year' },
    { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'createddate', numeric: false, disablePadding: false, label: 'Created Date' },
  ];
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={!setDir ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PushNotifications(props) {

  const dir = document.getElementsByTagName('html')[0].getAttribute('dir');

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 'calc(100%)',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      paddingLeft: 20,
      paddingRight: 20,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    grid: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginBottom: '10px',
    },
    total: {
      textAlign: "left",
    },
    totalDetails: {
      marginRight: '20px',
    },
    totalAttendes: {
      display: 'flex',
      alignItems: 'baseline',
      width: '30%',
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      '@media (max-width: 600px)': {
        width: "300px",
      }
    },
    progress: {
      width: '70%',
      '@media (max-width: 600px)': {
        width: "600px",
      }
    },
    h1: {
      margin: '0px',
      paddingRight: '10px',
      paddingLeft: '10px'
    },
    vl: {
      border: '1px solid #CACACA',
      height: ' 40px',
    },
    search: {
      position: 'relative',
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
    },
    inputInput: {
      // width: '90%',
      paddingLeft: '30px',
      paddingRight: '30px',
      borderBottom: '1px solid #CACACA'

    },
    inputRoot: {
      width: '100%',
    },
    button: {
      background: 'white',
      textTransform: 'capitalize'
    },
    exhibitor_image: {
      marginRight: '10px'
    },
    flex: {
      display: 'flex',
      alignItems: 'center'
    },
    dot: {
      height: '10px',
      width: '10px',
      backgroundColor: '#bbb',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '7px',
      marginLeft: '7px',
    },
    dotActive: {
      height: '10px',
      width: '10px',
      backgroundColor: '#4287F5',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '7px',
      marginLeft: '7px',
    },
    addButton: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end',
      width: '70%',
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      '@media (max-width: 600px)': {
        width: "300px",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    papernew: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      outline: 'none',
      padding: '10px 10px',
      width: '100%',
      borderRadius: '5px',
      overflow: 'auto',
      depth: 1,
      marginTop: '10px',
      marginBottom: '0px',
    },

  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalVisited, setTotalVisited] = React.useState(0);
  const history = useHistory();
  const [keys, set_keys] = React.useState([]);
  const [currentkey, set_currentkey] = React.useState(-1);
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const [sending, set_sending] = React.useState(false);

  const [notification_message, set_notification_message] = React.useState('');
  const [notification_message_error, set_notification_message_error] = React.useState(null);

  const pageLimits = [10, 20];
  let offset = 0;
  let searchStr = "";

  async function getList(nrows, newpage, search = "") {
    setShowBackdrop(true);
    let count = 10000;
    newpage = -1;
    let allkeys = [];
    while (true) {
      newpage += 1;
      if ((newpage * nrows) <= count) {
        offset = newpage * nrows;
        setPage(newpage);
      }
      else {
        break;
      }

      try {
        let url = config["baseurl"] + "/admin-users";

        let postObj = {};
        postObj["token"] = window.localStorage.getItem("token");
        // console.log(1);

        if (newpage > 0) {
          // console.log(2);
          // console.log("keys: ", allkeys);
          postObj["lastEvaluatedKey"] = JSON.parse(allkeys[newpage - 1]);
        }

        postObj["search"] = search;
        postObj["limit"] = nrows;
        // console.log(3);
        // console.log("postObj: ", postObj);

        const { data } = await axios.post(url, postObj);
        // console.log(data);
        if (data.error) {
          setErrorMessage(data.error);
          setShowError(true);
          return;
        }
        // console.log(4);
        // console.log("data.response.users.LastEvaluatedKey: ", data.response.users.LastEvaluatedKey);
        allkeys.push(JSON.stringify(data.response.users.LastEvaluatedKey));
        setTotalCount(data.response.count);
        count = data.response.count;
        // console.log(data.response.users);
        let newRows = [];
        // console.log(5);
        const dateFns = new DateFnsUtils();

        let users = data.response.users.Items;
        for (let i = 0; i < data.response.users.Items.length; ++i) {
          newRows.push(createData((newpage * nrows + i + 1),
            data.response.users.Items[i],
            dateFns.date(data.response.users.Items[i]["created-date"])
          ));
        }
        // console.log(6);

        if (currentkey === -1)
          set_currentkey(newpage);

        setRows(newRows);

        try {
          setShowBackdrop(true);
          let url = config["baseurl"] + "/notification-broadcast";

          console.log("--------------------", users);
          let postObj = {};
          postObj["notification_message"] = notification_message;
          postObj["token"] = window.localStorage.getItem("token");
          postObj["users"] = users;

          console.log(postObj);

          const { data } = await axios.post(url, postObj);
          // console.log("data: " + data);
          if (data.error) {
            setErrorMessage(data.error);
            setShowError(true);
            setShowBackdrop(false);
            return;
          }
        }
        catch (e) {
          console.log(e);
          if (e.response) {
            console.log("Error in sending notifications");
            setErrorMessage(e.response.data["message"]);
          }
          else {
            console.log("Error in sending notifications");
            setErrorMessage("Error in sending notifications: " + e);
          }
          setShowError(true);
          break;
        }
      }
      catch (e) {
        console.log(e.response);
        setShowBackdrop(false);
        if (e.response) {
          if (e.response.status === 403) {
            props.onAuthFailure();
          }
        }
        else {
          console.log("Error in getting users list");
          setErrorMessage("Error in getting users list");
          setShowError(true);
        }

        return;
      }
    }

    setShowBackdrop(false);
    set_sending(false);
  }

  useEffect(() => {
    // getList(rowsPerPage, 0);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowError(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    offset = newPage * rowsPerPage;
    setPage(newPage);
    console.log(newPage);
    getList(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    set_keys([]);
    offset = 0;
    getList(newRowsPerPage, 0);
  };

  const handleEdit = (userdata) => {
    console.log("handleEdit: ", userdata);

    props.setSelectedUser(userdata);
    props.history.push("/edituser");
  };

  const handleAddUser = () => {
    props.history.push("/addnewuser");
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },

  }))(LinearProgress);

  const getStringForArray = (data) => {
    let val = "";
    for (let i = 0; i < data.length; ++i) {
      if (i > 0)
        val += ", ";
      val += data[i];
    }
    return val;
  }

  const onSearchChange = (event) => {
    console.log(event.target.value);

    searchStr = event.target.value;
  };

  const handleSearch = () => {
    getList(rowsPerPage, 0, searchStr);
  };

  const onSend = async () => {
    console.log(notification_message);

    set_sending(true);

    getList(rowsPerPage, 0);
  };

  return (
    <div className={clsx(classes.root)}>
      {props.refreshUI &&

        <div className={classes.paper}>

          <EnhancedTableToolbar title={"Push Notification to all"} />
          {/* <Paper className={classes.grid}> */}
          <form className={classes.papernew} autoComplete="off" noValidate>
            <TextField size="small" className={classes.inputFields} id="formControl_notification_message" defaultValue={notification_message}
              label="Notification Message (To All)" variant="outlined" multiline disabled={sending}
              onChange={(event) => { set_notification_message(event.target.value); set_notification_message_error(null); }} />
            {notification_message_error && <Alert className={classes.alert} severity="error"> {notification_message_error} </Alert>}

            <div style={{ marginTop: 10 }}>
              <Button style={{ background: "#314293", color: "#FFFFFF" }} variant="contained" component="label" onClick={onSend} disabled={sending}>
                Send
              </Button>
            </div>

            {sending &&
              <span>sending {page * rowsPerPage} of {totalCount} </span>
            }

          </form>
          {/* </Paper> */}
        </div>

      }
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
