import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import profileLogo from "../assets/svg/ss/profile.svg";
// import Button from '@material-ui/core/Button';
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import EnhancedTableToolbar from "./enhancedToolbar";
import axios from "axios";
import config from "../config.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import lstrings from "../lstrings";
import DateFnsUtils from "@date-io/date-fns";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Filters from "./filters";
import PushNotificationSelected from "./pushNotificationSelected";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  DialogActions,
  Typography,
  Chip,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const initialMailChimpState = {
  tags: [],
  status: false,
  open: false,
  addTag: false,
  tagText: "",
  addTagErr: false,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function createData(slno, data, createddate, checked) {
  return { slno, data, createddate, checked };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Users(props) {
  const dir = document.getElementsByTagName("html")[0].getAttribute("dir");

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "calc(100%)",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      paddingLeft: 20,
      paddingRight: 20,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    grid: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: "10px",
    },
    total: {
      textAlign: "left",
    },
    totalDetails: {
      marginRight: "20px",
    },
    totalAttendes: {
      display: "flex",
      alignItems: "baseline",
      width: "30%",
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      "@media (max-width: 600px)": {
        width: "300px",
      },
    },
    progress: {
      width: "70%",
      "@media (max-width: 600px)": {
        width: "600px",
      },
    },
    h1: {
      margin: "0px",
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    vl: {
      border: "1px solid #CACACA",
      height: " 40px",
    },
    search: {
      position: "relative",
    },
    searchIcon: {
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
    },
    inputInput: {
      // width: '90%',
      paddingLeft: "30px",
      paddingRight: "30px",
      borderBottom: "1px solid #CACACA",
    },
    inputRoot: {
      width: "100%",
    },
    button: {
      background: "white",
      textTransform: "capitalize",
    },
    exhibitor_image: {
      marginRight: "10px",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      height: "10px",
      width: "10px",
      backgroundColor: "#bbb",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "7px",
      marginLeft: "7px",
    },
    dotActive: {
      height: "10px",
      width: "10px",
      backgroundColor: "#4287F5",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "7px",
      marginLeft: "7px",
    },
    addButton: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-end",
      width: "70%",
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      "@media (max-width: 600px)": {
        width: "300px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const history = useHistory();
  const [keys, set_keys] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [searchStr, setSearchStr] = React.useState(false);
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filtersSelected, setFiltersSelected] = React.useState({
    "0": 0,
    "1": 0,
    "2": 0,
  });
  const [selectedRows, setSelectedRows] = React.useState(false);
  // const [filtersList, setFiltersList] = React.useState(['iOS', 'Android']);
  // const [sortFiltersList, setSortFiltersList] = React.useState([
  //   'Created Date Desc',
  //   'UserName Asc',
  //   'UserName Desc',
  // ]);
  const [sortFilterSelected, setSortFilterSelected] = React.useState(
    "Created Date Desc"
  );
  // const [searchByFiltersList, setSearchByFiltersList] = React.useState([
  //   'Username',
  //   'Email',
  //   'Country',
  // ]);
  const [searchByFilterSelected, setSearchByFilterSelected] = React.useState(
    "Username"
  );
  // const [filterOptions, setFilterOptions] = React.useState({});
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [
    showSendPushNotification,
    setShowSendPushNotification,
  ] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [mailChimp, setMailChimp] = useState({ ...initialMailChimpState });
  const [selectAll, setSelectAll] = useState(false);
  const [
    filterByEmailAssociatedOrNot,
    setFilterByEmailAssociatedOrNot,
  ] = useState({
    open: false,
    emailAssociated: false,
    emailNotAssociated: false,
  });

  const pageLimits = [10, 50, 100, 250];
  let offset = 0;

  async function getList(
    nrows,
    newpage,
    search = "",
    filterByEmailAssociatedOrNot
  ) {
    setShowBackdrop(true);
    try {
      let url = config["baseurl"] + "/admin-users2";

      let postObj = {};
      postObj["token"] = window.localStorage.getItem("token");
      postObj["offset"] = newpage * nrows;
      postObj["search"] = search;
      postObj["limit"] = nrows;

      if (filtersSelected["0"] == 1 && filtersSelected["1"] == 1) {
      } else if (filtersSelected["0"] == 1) {
        postObj["ios"] = 1;
      } else if (filtersSelected["1"] == 1) {
        postObj["android"] = 1;
      } else if (filtersSelected["2"] == 1) {
        postObj["web"] = 1;
      }

      if (sortFilterSelected == "Created Date Desc") {
        postObj["sort_created_date_desc"] = 1;
      } else if (sortFilterSelected == "UserName Asc") {
        postObj["sort_username_asc"] = 1;
      } else if (sortFilterSelected == "UserName Desc") {
        postObj["sort_username_dec"] = 1;
      }

      if (searchByFilterSelected == "Username") {
        postObj["searchby"] = "username";
      } else if (searchByFilterSelected == "Email") {
        postObj["searchby"] = "email";
      } else if (searchByFilterSelected == "Country") {
        postObj["searchby"] = "country";
      } else if (searchByFilterSelected == "Invite Code") {
        postObj["searchby"] = "admininvite";
      }
      if (
        !filterByEmailAssociatedOrNot?.emailNotAssociated &&
        filterByEmailAssociatedOrNot?.emailAssociated
      ) {
        postObj["emailAssociated"] = true;
      }
      if (
        filterByEmailAssociatedOrNot?.emailNotAssociated &&
        !filterByEmailAssociatedOrNot?.emailAssociated
      ) {
        postObj["emailAssociated"] = false;
      }

      const { data } = await axios.post(url, postObj);
      console.log(data);
      if (data.error) {
        setErrorMessage(data.error);
        setShowError(true);
        return;
      }

      setTotalCount(data.users.totalDocs);
      let newRows = [];

      const dateFns = new DateFnsUtils();

      for (let i = 0; i < data.users.docs.length; ++i) {
        newRows.push(
          createData(
            newpage * nrows + i + 1,
            data.users.docs[i],
            dateFns.date(data.users.docs[i]["created_date"]),
            false
          )
        );
      }

      setRows(newRows);
      setShowBackdrop(false);
    } catch (e) {
      console.log(e.response);
      setShowBackdrop(false);
      if (e.response) {
        if (e.response.status === 403) {
          props.onAuthFailure();
          return;
        }
      } else {
        console.log("Error in getting users list");
        setErrorMessage("Error in getting users list");
        setShowError(true);
      }
    }
  }
  useEffect(() => {
    setRows([]);
    setSelectAll(false);
  }, [rowsPerPage, searchStr, sortFilterSelected, filtersSelected, page]);
  useEffect(() => {
    getList(rowsPerPage, 0, searchStr);

    check_session_valid();
  }, [filtersSelected, sortFilterSelected]);

  async function check_session_valid() {
    try {
      let url = config["baseurl"] + "/session-valid";

      let postObj = {};
      postObj["token"] = window.localStorage.getItem("token");

      const { data } = await axios.post(url, postObj);
      console.log("data: ", data);
      if (data.error) {
        props.handleSignOut();
        return;
      }
    } catch (e) {
      console.error(e);
      props.handleSignOut();
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name); // Get all row names
      setSelected(newSelecteds); // Update selected state
      setSelectedUsers(true); // Mark that rows are selected
      return;
    }
    setSelected([]); // Clear selections if unchecked
  };

  const handleChangePage = (event, newPage) => {
    offset = newPage * rowsPerPage;
    setPage(newPage);
    console.log(newPage);
    getList(rowsPerPage, newPage, searchStr);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    set_keys([]);
    offset = 0;
    getList(newRowsPerPage, 0, searchStr);
  };

  // const handleEdit = (userdata) => {
  //   console.log('handleEdit: ', userdata);

  //   props.setSelectedUser(userdata);
  //   props.history.push('/edituser');
  // };

  // const handleAddUser = () => {
  //   props.history.push('/addnewuser');
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleMailChimp = (type, tag, text) => {
    if (type === "status") {
      setMailChimp((state) => ({ ...state, status: !state.status }));
    } else if (type === "tags") {
      let tags = [...mailChimp.tags];
      if (!tags.includes(tag)) {
        tag !== "" && tags.push(tag);
      } else {
        tags = tags.filter((tg) => tg !== tag);
      }
      setMailChimp((state) => ({ ...state, tagText: "", addTag: false, tags }));
    } else if (type === "open") {
      if (!mailChimp.open === false) {
        setMailChimp(initialMailChimpState);
      } else {
        setMailChimp((state) => ({ ...state, open: !state.open }));
      }
    } else if (type === "addTag") {
      const addTag = !mailChimp.addTag;
      const obj = {};
      obj["addTag"] = addTag;
      if (addTag === false) {
        obj["tagText"] = "";
      }
      setMailChimp((state) => ({
        ...state,
        ...obj,
      }));
    } else if (type === "tagText") {
      setMailChimp((state) => ({ ...state, tagText: text, addTagErr: false }));
    } else if (type === "addTagErr") {
      setMailChimp((state) => ({ ...state, addTagErr: true }));
    }
  };

  console.log(mailChimp);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }))(LinearProgress);

  const onSearchChange = (event) => {
    console.log(event.target.value);
    console.log(filterByEmailAssociatedOrNot);
    setPage(0);

    setSearchStr(event.target.value);
    // searchStr = event.target.value;
    getList(rowsPerPage, 0, event.target.value);
  };

  const sendInvite = async (data) => {
    setShowBackdrop(true);
    try {
      let url = config["baseurl"] + "/admininvite";
      let postObj = {};
      postObj["token"] = window.localStorage.getItem("token");
      postObj["username"] = data.username;
      postObj["email"] = data.email;

      await axios.patch(url, postObj);
    } catch (e) {
      console.log("Error in saving business card");
    }

    setShowBackdrop(false);

    getList(rowsPerPage, page, searchStr);
  };

  // const sendInviteToAll = async () => {
  //   setShowBackdrop(true);
  //   let url = config['baseurl'] + '/admininvite';

  //   for (let i = 0; i < rows.length; ++i) {
  //     let row = rows[i];
  //     if (
  //       (row.data.admininvite && row.data.admininvite.length > 0) ||
  //       row.data.invite_verified != 'waiting'
  //     ) {
  //       // Skip this as it already have invite code
  //     } else {
  //       try {
  //         let postObj = {};
  //         postObj['token'] = window.localStorage.getItem('token');
  //         postObj['username'] = row.data.username;
  //         postObj['email'] = row.data.email;

  //         await axios.patch(url, postObj);
  //       } catch (e) {
  //         console.log('Error in saving business card');
  //       }
  //     }
  //   }

  //   setShowBackdrop(false);
  //   getList(rowsPerPage, page, searchStr);
  // };

  const closeFilters = () => {
    setFiltersOpen(false);
  };

  const handleChange = (row, index) => {
    let newRows = [...rows];
    newRows[index].checked = newRows[index].checked == true ? false : true;
    setRows(newRows);

    const allSelected = newRows.every((row) => row.checked);
    setSelectAll(allSelected); // Update the head checkbox state
  };
  const handleSelectAll = () => {
    const newCheckedState = !selectAll; // Toggle select all state
    const updatedRows = rows.map((row) => ({
      ...row,
      checked: newCheckedState,
    }));

    setRows(updatedRows);
    setSelectAll(newCheckedState);
  };
  function EnhancedTableHead(props) {
    const dir = document.getElementsByTagName("html")[0].getAttribute("dir");
    const setDir = dir === "rtl" ? true : false;

    const headCells = [
      {
        id: "check",
        numeric: true,
        disablePadding: true,
        label: (
          <Checkbox
            size="small"
            style={{
              marginLeft: "15px",
              color: selectAll
                ? "#F50057"
                : rows.some((row) => row.checked) && !selectAll
                ? "#F50057"
                : "rgba(0, 0, 0, 0.54)",
            }}
            checked={selectAll ? true : false}
            indeterminate={rows.some((row) => row.checked) && !selectAll}
            onChange={handleSelectAll} // Select all or deselect all
          />
        ),
      },
      { id: "slno", numeric: true, disablePadding: true, label: "Username" },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Parent Email",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email Verified",
      },
      {
        id: "birthyear",
        numeric: false,
        disablePadding: false,
        label: "Birth Year",
      },
      {
        id: "country",
        numeric: false,
        disablePadding: false,
        label: "Country",
      },
      { id: "device", numeric: false, disablePadding: false, label: "Device" },
      {
        id: "createddate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
      },
      {
        id: "admininvitecode",
        numeric: false,
        disablePadding: false,
        label: "Admin Invited Code",
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      },
    ];
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={!setDir ? "left" : "right"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
              {/* </TableSortLabel> */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const sendNotification = () => {
    let newselected = [];
    for (let i = 0; i < rows.length; ++i) {
      if (rows[i].checked) {
        newselected.push(rows[i].data);
      }
    }

    setSelectedUsers(newselected);
    console.log(newselected);
    setShowSendPushNotification(true);
  };

  const closeNotificationView = () => {
    setShowSendPushNotification(false);
  };

  async function sendNotificationNow(notification_message) {
    setShowSendPushNotification(false);
    if (notification_message.length == 0) return;

    try {
      setShowBackdrop(true);
      let url = `${config["baseurl"]}/notification-broadcast`;

      let postObj = {};
      postObj["notification_message"] = notification_message;
      postObj["token"] = window.localStorage.getItem("token");
      postObj["users"] = selectedUsers;

      const { data } = await axios.post(url, postObj);
      if (data.error) {
        setErrorMessage(data.error);
        setShowError(true);
        return;
      }
      setShowSuccess(true);
    } catch (e) {
      console.log(e);
      if (e.response) {
        console.log("Error in sending notifications");
        setErrorMessage(e.response.data["message"]);
      } else {
        console.log("Error in sending notifications");
        setErrorMessage("Error in sending notifications: " + e);
      }
      setShowError(true);
    }
    setShowBackdrop(false);
  }

  const pushContactsToMailChimp = async () => {
    const selectedUserList = rows
      .filter((row) => row.checked)
      .map((row) => row.data);
    const contacts = selectedUserList.map((user) => ({
      email: user.email,
      username: user?.username,
      country: user.country,
      phonenumber: user?.phonenumber,
      birthYear: user?.birthYear,
    }));

    try {
      handleMailChimp("open");
      setShowBackdrop(true);
      let url = `${config["baseurl"]}/mail-chimp`;

      let postObj = {};
      postObj["contacts"] = contacts;
      postObj["token"] = window.localStorage.getItem("token");
      postObj["tags"] = mailChimp.tags;
      postObj["status"] = mailChimp.status;

      console.log(postObj);

      const { data } = await axios.post(url, postObj);
      console.log("data: ", data);
      if (data.error) {
        setErrorMessage(data.error);
        setShowError(true);
        return;
      }
      setShowSuccess(true);
    } catch (e) {
      console.log(e);
      if (e.response) {
        console.log("Error in sending notifications");
        setErrorMessage(e.response.data["message"]);
      } else {
        console.log("Error in sending notifications");
        setErrorMessage("Error in sending notifications: " + e);
      }
      setShowError(true);
    }
    setShowBackdrop(false);
  };

  return (
    <Box className={clsx(classes.root)}>
      {props.refreshUI && (
        <div className={classes.paper}>
          <EnhancedTableToolbar title={lstrings.Users} />
          <Paper className={classes.grid} key="1">
            <Grid container spacing={2}>
              <Grid item className={classes.totalAttendes}>
                <img src={profileLogo} width="25" alt="" />
                <h1 className={classes.h1}>{totalCount}</h1>
                <span>{lstrings.Users}</span>
              </Grid>
              <Grid item className={classes.addButton}>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => sendNotification()}
                  variant="contained"
                >
                  {"Send Notification"}
                </Button>
                <Button
                  sx={{ textTransform: "none", ml: "16px" }}
                  onClick={() => {
                    const selectedUserList = rows
                      .filter((row) => row.checked)
                      .map((row) => row.data);
                    if (selectedUserList.length > 0) {
                      handleMailChimp("open");
                    }
                  }}
                  variant="contained"
                >
                  {"Push to mail chimp"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.grid} key="2">
            <div className={classes.search}>
              <div style={{ display: "flex" }}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={onSearchChange}
                />
                <FormControl size="small">
                  <IconButton
                    id="openMenu"
                    aria-label="filter list"
                    size="small"
                    onClick={() => setFiltersOpen(true)}
                  >
                    <FilterListIcon />
                  </IconButton>
                </FormControl>
              </div>
            </div>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />

                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.slno}>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            <Checkbox
                              size="small"
                              checked={row.checked}
                              onChange={() => handleChange(row, index)}
                            />
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {"" + row.slno + ". " + row.data.username}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.email}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.emailVerified === 1 ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.birthYear}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.country}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.device ? row.data.device : "---"}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.createddate.toDateString()}
                          </TableCell>
                          <TableCell align={dir === "rtl" ? "right" : "left"}>
                            {row.data.admininvite}
                          </TableCell>
                          {row.data.invite_verified === "waiting" && (
                            <TableCell align={dir === "rtl" ? "right" : "left"}>
                              <Button
                                size="small"
                                onClick={() => sendInvite(row.data)}
                                style={{
                                  textTransform: "none",
                                }}
                                variant="contained"
                                className={classes.button}
                              >
                                {"Send Invite"}
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={pageLimits}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}

      {filtersOpen && (
        <Filters
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          searchByFilterSelected={searchByFilterSelected}
          setSearchByFilterSelected={setSearchByFilterSelected}
          sortFilterSelected={sortFilterSelected}
          setSortFilterSelected={setSortFilterSelected}
          getList={getList}
          rowsPerPage={rowsPerPage}
          page={page}
          searchStr={searchStr}
          setFilterByEmailAssociatedOrNot={setFilterByEmailAssociatedOrNot}
          filterByEmailAssociatedOrNot={filterByEmailAssociatedOrNot}
          closeAction={closeFilters}
        />
      )}

      {showSendPushNotification && selectedUsers.length > 0 && (
        <PushNotificationSelected
          close={closeNotificationView}
          send={sendNotificationNow}
        />
      )}

      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {"Sending notification completed successfully"}
        </Alert>
      </Snackbar>

      <Dialog
        open={mailChimp.open}
        onClose={() => handleMailChimp("open")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send user details to mailchimp"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "500px",
            }}
          >
            <Box>
              <Typography> Subscribed </Typography>
            </Box>
            <Box>
              <Switch
                checked={mailChimp.status}
                onClick={() => handleMailChimp("status")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "500px",
            }}
          >
            <Typography> Tags </Typography>
            <Button
              sx={{ textTransform: "none" }}
              onClick={(e) => handleMailChimp("addTag")}
            >
              Add <AddIcon />
            </Button>
          </Box>

          <Box
            sx={{
              border: mailChimp.tags?.length > 0 ? "1px solid #eee" : "",
              borderRadius: mailChimp.tags?.length > 0 ? "3px" : "",
              p: mailChimp.tags?.length > 0 ? "8px" : "",
            }}
          >
            {mailChimp?.tags?.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleMailChimp("tags", tag)}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleMailChimp("open")}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={pushContactsToMailChimp}
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={mailChimp.addTag}
        onClose={() => handleMailChimp("addTag")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography> Add Tag </Typography>
          <CloseIcon onClick={() => handleMailChimp("addTag")} />
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: "1rem", width: "300px" }}
            variant="outlined"
            value={mailChimp.tagText}
            label="Enter tag"
            onChange={(e) => handleMailChimp("tagText", false, e.target.value)}
          />
          {mailChimp.addTagErr && (
            <small style={{ color: "red", display: "block" }}>
              {" "}
              This tag already added{" "}
            </small>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (mailChimp.tags.includes(mailChimp.tagText)) {
                handleMailChimp("addTagErr");
              } else {
                handleMailChimp("tags", mailChimp.tagText);
              }
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
